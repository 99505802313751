import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css'
import duatoBrand from '../images/logos/duatoBrand.png'
// import { Navbar, Nav } from 'bootstrap-4-react'
import './navBar.css'
import {
  // Collapse,
  Navbar,
  // NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from 'reactstrap'

const MainNavBar = () => (
  <div className="nav-bar__container">
    {/* <Navbar sticky="top" mb="3">
      <Link to="/">
        <Navbar.Brand href="#top">
          <img className="nav-bar__img" src={duatoBrand} alt="Duato" />
        </Navbar.Brand>
      </Link>
      <Nav>
        <Link to="#services">
          <Nav.ItemLink> SERVICES</Nav.ItemLink>
        </Link>
        <Link to="#timeline">
          <Nav.ItemLink> TIMELINE</Nav.ItemLink>
        </Link>
        <Link to="aboutUs">
          <Nav.ItemLink> ABOUT US</Nav.ItemLink>
        </Link>
      </Nav>
    </Navbar> */}

    <Navbar color="black" expand="md" className="nav-bar__container">
      <Link to="/">
        <NavbarBrand href="/">
          <img className="nav-bar__img" src={duatoBrand} alt="Duato" />
        </NavbarBrand>
      </Link>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Link to="#services">
            <NavLink href="#services" className="text-white">
              SERVICES
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="#timeline">
            <NavLink className="text-white" href="#timeline">
              TIMELINE
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="aboutUs">
            <NavLink className="text-white">ABOUT US</NavLink>
          </Link>
        </NavItem>
      </Nav>
    </Navbar>
  </div>
)

export { MainNavBar }
