import React from 'react'
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
const MarketingSolutions = () => (
  <StaticQuery
    query = {graphql`
      query {
        marketingSolutionsFluid: file(
          relativePath: { eq: "slider/head-pic01.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Img fluid={data.marketingSolutionsFluid.childImageSharp.fluid} />
        <h1 id="titlemarketingsolutions">Marketing Solutions</h1>

        <p>
          <strong>DUATO</strong> completes its offer with the branch Marketing
          Solutions. This service offers a bunch of Marketing Tools and
          Consultancy Services that complete the Quality Control Service and the
          Interior Design Projects propositions. <strong>DUATO</strong> has the
          capacity and knowledge of covering all the services from the product
          research to its promotion. Covering each step of the path gives us a
          global idea of what the needs are at each moment and we are able to
          anticipate difficulties by adding solutions at the right stage making
          things clear, easy and efficient for you.
        </p>

        <p>
          Some of the Marketing Solutions Proposed are: Market Research for you
          desired product Corporate and Brand Image Design (Name, Logo, Graphic
          Chart…) Collateral Creation (Catalogs, Brochures, Business Card,
          Stands…) Displays Branded Present Professional Photography Advertising
          Web page Creation Online Catalogs, Brochures and other Creative
          Interactive tools Social Media Solutions(Computer and Mobile based) WE
          MAKE YOUR IDEAS REALITY.
        </p>

        <p>
          Due to the technological know-how and creativity of the team other new
          tools and idea can be implemented. Don’t doubt to ask us for something
          that looks impossible, we might surprise you by making it!
        </p>
      </Layout>
    )}
  />
)
export default MarketingSolutions
