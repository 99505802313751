// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { MainNavBar } from '../components/navBar'
import './header.css'
const Header = () => (
  <div className="header__container">
    <MainNavBar />
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Duato`,
}

export default Header
