import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import blackDuato from '../images/logos/logo2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faWeixin } from '@fortawesome/free-brands-svg-icons'
import Header from './header'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: `100%`,
            // padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {children}
          <footer className="footer">
            <div className="logo-section">
              <img src={blackDuato} alt="Duato" className="image-footer" />
              <span>
                <FontAwesomeIcon icon={faPhone} /> 0757-8226 9665 |{' '}
                <FontAwesomeIcon icon={faEnvelope} /> info@duato-hk.com |{' '}
                <a href="https://wechat.com/duato2005">
                  <FontAwesomeIcon icon={faWeixin} size="lg" />
                </a>
              </span>
            </div>
            <div className="copy-rights">
              © {new Date().getFullYear()}, Duato. Building your dreams
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
